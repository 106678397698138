// eslint-disable-next-line import/no-unused-modules
export const weeks = [
  {
    startDay: '01/08/2023',
    endDay: '08/08/2023',
    startTimestamp: 1690902000,
    endTimestamp: 1691510400,
  },
  {
    startDay: '09/08/2023',
    endDay: '15/08/2023',
    startTimestamp: 1691510400,
    endTimestamp: 1692115200,
  },
  {
    startDay: '16/08/2023',
    endDay: '22/08/2023',
    startTimestamp: 1692115200,
    endTimestamp: 1692720000,
  },
  {
    startDay: '23/08/2023',
    endDay: '29/08/2023',
    startTimestamp: 1692720000,
    endTimestamp: 1693324800,
  },
  {
    startDay: '30/08/2023',
    endDay: '05/09/2023',
    startTimestamp: 1693324800,
    endTimestamp: 1693929600,
  },
  {
    startDay: '06/09/2023',
    endDay: '12/09/2023',
    startTimestamp: 1693929600,
    endTimestamp: 1694534400,
  },
  {
    startDay: '13/09/2023',
    endDay: '19/09/2023',
    startTimestamp: 1694534400,
    endTimestamp: 1695139200,
  },
  {
    startDay: '20/09/2023',
    endDay: '26/09/2023',
    startTimestamp: 1695139200,
    endTimestamp: 1695744000,
  },
  {
    startDay: '20/09/2023',
    endDay: '03/10/2023',
    startTimestamp: 1695744000,
    endTimestamp: 1696348800,
  },
  {
    startDay: '04/10/2023',
    endDay: '09/10/2023',
    startTimestamp: 1696348800,
    endTimestamp: 1696867200,
  },
]
