export const ArrowSwitch = ({ onSwitchTokens }: { onSwitchTokens?: () => void }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" fill="none">
      <g filter="url(#filter0_d_2_52)" onClick={() => onSwitchTokens && onSwitchTokens()}>
        <rect x="110" y="10" width="100" height="100" rx="50" transform="rotate(90 110 10)" fill="#AFBDC8" />
        <rect
          x="112"
          y="8"
          width="104"
          height="104"
          rx="52"
          transform="rotate(90 112 8)"
          stroke="#D0DCE4"
          strokeWidth="4"
        />
      </g>
      <path
        d="M57.6176 78.0461C57.8862 65.8239 57.9534 53.5345 57.7519 41.3122C57.7519 39.1632 61.1097 39.1632 61.1097 41.3122C61.3111 53.5345 61.244 65.8239 60.9754 78.0461C60.9082 80.1951 57.5504 80.1951 57.6176 78.0461Z"
        fill="white"
        onClick={() => onSwitchTokens && onSwitchTokens()}
      />
      <path
        d="M69.2354 72.0693C67.4894 73.681 65.7434 75.3599 63.9973 76.9716C63.1243 77.7775 62.3184 78.5834 61.4454 79.3892C60.5053 80.2623 59.5651 81.2696 58.1548 80.9338C57.0132 80.6652 56.3416 79.5907 55.6701 78.7177C54.9985 77.7775 54.327 76.8373 53.5883 75.9643C52.178 74.0168 50.7006 72.0693 49.2903 70.1218C48.7531 69.3831 49.156 68.2415 49.8947 67.8385C50.7677 67.3684 51.6408 67.7042 52.178 68.4429C53.3196 70.0547 54.5284 71.5992 55.6701 73.211C56.2745 74.0168 56.8117 74.8227 57.4161 75.5614C57.6847 75.9643 58.0205 76.3673 58.2891 76.7702C58.3563 76.9045 59.095 77.6432 59.0278 77.7775C58.7592 77.7775 58.4906 77.7775 58.222 77.7104C58.2891 77.6432 58.3563 77.576 58.4906 77.5089C58.6249 77.3074 58.8264 77.1731 58.9607 77.0388C59.2965 76.703 59.6994 76.3672 60.0352 76.0315C60.7739 75.3599 61.5126 74.6884 62.2513 73.9497C63.7287 72.5394 65.2733 71.1291 66.7507 69.7189C68.4296 68.2415 70.8472 70.5919 69.2354 72.0693Z"
        fill="white"
        onClick={() => onSwitchTokens && onSwitchTokens()}
      />
      <path
        d="M67.0865 50.4452C65.6091 49.0349 64.0645 47.5575 62.5871 46.1472C61.8484 45.4757 61.1768 44.8041 60.4381 44.0654C60.0352 43.6625 59.6323 43.3267 59.2293 42.9238C59.095 42.7894 58.8935 42.588 58.7592 42.4537C58.6249 42.3194 58.4906 42.2522 58.4235 42.1179C58.2891 41.9836 58.2891 41.9836 58.4235 42.0507C58.6921 42.0507 59.0279 42.0507 59.2965 42.0507C59.6323 41.9836 59.4979 41.715 59.4308 42.0507C59.3636 42.3194 59.095 42.5208 58.9607 42.7223C58.6921 43.1924 58.3563 43.6625 58.0877 44.1325C57.4833 45.0727 56.8789 45.9457 56.2745 46.8859C55.0657 48.6991 53.9241 50.5795 52.7153 52.3927C51.5736 54.2058 48.6188 52.527 49.8276 50.7138C51.1707 48.632 52.5138 46.5501 53.8569 44.5355C54.5285 43.461 55.2 42.3865 55.9387 41.3792C56.5431 40.439 57.0804 39.2302 58.2892 38.8944C59.6323 38.4915 60.5724 39.4988 61.4454 40.3047C62.3185 41.1106 63.1915 41.9836 64.0645 42.7894C65.8777 44.5355 67.6909 46.2815 69.5041 48.0276C71.0487 49.5721 68.6311 51.9226 67.0865 50.4452Z"
        fill="white"
        onClick={() => onSwitchTokens && onSwitchTokens()}
      />
      <defs>
        <filter
          id="filter0_d_2_52"
          x="0"
          y="0"
          width="120"
          height="120"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="6" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2_52" />
          <feOffset />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.898507 0 0 0 0 0.93079 0 0 0 0 0.954167 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_52" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_52" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
