/* eslint-disable import/no-unused-modules */

export interface _1INCH_TOKEN_DETAILS {
  symbol: string
  name: string
  decimals: number
  address: string
  logoURI: string
  wrappedNative?: boolean
  tags: string[]
  chainId?: number
}

export const XYFINANCE_NATIVE_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'

export const BIND_XY_MAINNET: { [key: string]: _1INCH_TOKEN_DETAILS & { coingeckoId?: string } } = {
  ETH: {
    symbol: 'ETH',
    name: 'Ether',
    decimals: 18,
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    logoURI: 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
    tags: ['native', 'PEG:ETH'],
    coingeckoId: 'ethereum',
  },
  WETH: {
    symbol: 'WETH',
    name: 'Wrapped Ether',
    address: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
    wrappedNative: true,
    tags: ['tokens', 'PEG:ETH'],
    coingeckoId: 'weth',
  },
  USDC: {
    symbol: 'USDC',
    name: 'USD Coin',
    address: '0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4',
    decimals: 6,
    logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
    tags: ['tokens', 'PEG:USD'],
    coingeckoId: 'usd-coin',
  },
  COMBO: {
    symbol: 'COMBO',
    name: 'Furucombo',
    decimals: 18,
    address: '0xc2b13bb90e33f1e191b8aa8f44ce11534d5698e3',
    logoURI: 'https://tokens.1inch.io/324/0xc2b13bb90e33f1e191b8aa8f44ce11534d5698e3.png',
    tags: ['tokens'],
    coingeckoId: 'mute',
  },
  PERP: {
    symbol: 'PERP',
    name: 'Perpetual',
    decimals: 18,
    address: '0x42c1c56be243c250ab24d2ecdcc77f9ccaa59601',
    logoURI: 'https://tokens.1inch.io/0x42c1c56be243c250ab24d2ecdcc77f9ccaa59601.png',
    tags: ['tokens'],
    coingeckoId: 'perpetual-protocol',
  },
  ZKDOGE: {
    symbol: 'ZKDOGE',
    name: 'zkDoge',
    decimals: 18,
    address: '0xbfb4b5616044eded03e5b1ad75141f0d9cb1499b',
    logoURI: 'https://tokens.1inch.io/324/0xbfb4b5616044eded03e5b1ad75141f0d9cb1499b.png',
    tags: ['tokens'],
    coingeckoId: 'zkdoge',
  },
  ZKSHIB: {
    symbol: 'ZKSHIB',
    name: 'Zksync Shib',
    decimals: 18,
    address: '0x5e38cb3e6c0faafaa5c32c482864fcef5a0660ad',
    logoURI: 'https://tokens.1inch.io/324/0x5e38cb3e6c0faafaa5c32c482864fcef5a0660ad.png',
    tags: ['tokens'],
    coingeckoId: 'zkshib',
  },
  VC: {
    symbol: 'VC',
    name: 'Velocore',
    decimals: 18,
    address: '0x85d84c774cf8e9ff85342684b0e795df72a24908',
    logoURI: 'https://tokens.1inch.io/324/0x85d84c774cf8e9ff85342684b0e795df72a24908.png',
    tags: ['tokens'],
    coingeckoId: 'velocore',
  },
  ZGEM: {
    symbol: 'ZGEM',
    name: 'ZkSync Gem Token',
    decimals: 18,
    address: '0x2b64237277c605d07f17b96f9627712340c32981',
    logoURI: 'https://tokens.1inch.io/324/0x2b64237277c605d07f17b96f9627712340c32981.png',
    tags: ['tokens'],
    coingeckoId: 'gemswap-2',
  },
  WISP: {
    symbol: 'WISP',
    name: 'Whisper',
    decimals: 18,
    address: '0xc8ec5b0627c794de0e4ea5d97ad9a556b361d243',
    logoURI: 'https://tokens.1inch.io/324/0xc8ec5b0627c794de0e4ea5d97ad9a556b361d243.png',
    tags: ['tokens'],
    coingeckoId: 'whisper',
  },
  ZYN: {
    symbol: 'ZYN',
    name: 'Zynergy',
    decimals: 18,
    address: '0x0231b3de40b6b3bdd28dcef037f1b7a3fcf5a95a',
    logoURI: 'https://tokens.1inch.io/0x0231b3de40b6b3bdd28dcef037f1b7a3fcf5a95a.png',
    tags: ['tokens'],
    coingeckoId: 'zynergy',
  },
  HUSH: {
    symbol: 'HUSH',
    name: 'Hush',
    decimals: 18,
    address: '0x6d27b6252b980ba4350a1e349a3b684237d5e986',
    logoURI: 'https://tokens.1inch.io/0x6d27b6252b980ba4350a1e349a3b684237d5e986.png',
    tags: ['tokens'],
    coingeckoId: 'hush-cash',
  },
  LUSD: {
    symbol: 'LUSD',
    name: 'LUSD Stablecoin',
    decimals: 18,
    address: '0x503234f203fc7eb888eec8513210612a43cf6115',
    logoURI: 'https://tokens.1inch.io/0x503234f203fc7eb888eec8513210612a43cf6115.png',
    tags: ['tokens'],
    coingeckoId: 'liquity-usd',
  },
  MVX: {
    symbol: 'MVX',
    name: 'Metavault Trade',
    decimals: 18,
    address: '0xc8ac6191cdc9c7bf846ad6b52aaaa7a0757ee305',
    logoURI: 'https://tokens.1inch.io/0xc8ac6191cdc9c7bf846ad6b52aaaa7a0757ee305.png',
    tags: ['tokens'],
    coingeckoId: 'metavault-trade',
  },
  SIS: {
    symbol: 'SIS',
    name: 'Symbiosis',
    decimals: 18,
    address: '0xdd9f72afed3631a6c85b5369d84875e6c42f1827',
    logoURI: 'https://tokens.1inch.io/0xdd9f72afed3631a6c85b5369d84875e6c42f1827.png',
    tags: ['tokens'],
    coingeckoId: 'symbiosis-finance',
  },
  'USD+': {
    symbol: 'USD+',
    name: 'USD+',
    decimals: 6,
    address: '0x8e86e46278518efc1c5ced245cba2c7e3ef11557',
    logoURI: 'https://tokens.1inch.io/0x8e86e46278518efc1c5ced245cba2c7e3ef11557.png',
    tags: ['tokens'],
    coingeckoId: 'usd',
  },
  USDT: {
    symbol: 'USDT',
    name: 'USDT',
    decimals: 6,
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    chainId: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/325/small/Tether.png?1668148663',
    tags: ['tokens'],
    coingeckoId: 'tether',
  },
  Cheems: {
    symbol: 'Cheems',
    name: 'Cheems',
    decimals: 18,
    address: '0xd599dA85F8Fc4877e61f547dFAcffe1238A7149E',
    chainId: 324,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/24988.png',
    tags: ['tokens'],
    coingeckoId: 'cheems-token',
  },
  MUTE: {
    symbol: 'MUTE',
    name: 'MUTE',
    decimals: 18,
    address: '0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42',
    chainId: 324,
    logoURI: 'https://assets.coingecko.com/coins/images/14331/small/MUTE.png?1617618967',
    tags: ['tokens'],
    coingeckoId: 'mute',
  },
  USDbC: {
    symbol: 'USDbC',
    name: 'USDbC',
    decimals: 6,
    address: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    chainId: 8453,
    logoURI: 'https://assets.coingecko.com/coins/images/31164/small/baseusdc.jpg?1691075428',
    tags: ['tokens'],
    coingeckoId: 'bridged-usd-coin-base',
  },
  axlUSDC: {
    symbol: 'axlUSDC',
    name: 'axlUSDC',
    decimals: 6,
    address: '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
    chainId: 8453,
    logoURI: 'https://assets.coingecko.com/coins/images/26476/small/uausdc_D_3x.png?1690776252',
    tags: ['tokens'],
    coingeckoId: 'axlusdc',
  },
  cbETH: {
    symbol: 'cbETH',
    name: 'cbETH',
    decimals: 18,
    address: '0xbe9895146f7af43049ca1c1ae358b0541ea49704',
    chainId: 8453,
    logoURI: 'https://assets.coingecko.com/coins/images/27008/small/cbeth.png?1661390066',
    tags: ['tokens'],
    coingeckoId: 'coinbase-wrapped-staked-eth',
  },
  DAI: {
    symbol: 'DAI',
    name: 'DAI',
    decimals: 18,
    address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    chainId: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/9956/small/Badge_Dai.png?1687143508',
    tags: ['tokens'],
    coingeckoId: 'dai',
  },
  MIM: {
    symbol: 'MIM',
    name: 'MIM',
    decimals: 18,
    address: '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3',
    chainId: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png?1624979612',
    tags: ['tokens'],
    coingeckoId: 'magic-internet-money',
  },
  PEPE: {
    symbol: 'PEPE',
    name: 'Pepe',
    decimals: 18,
    address: '0x6982508145454ce325ddbe47a25d4ec3d2311933',
    chainId: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/29850/small/pepe-token.jpeg?1682922725',
    tags: ['tokens'],
    coingeckoId: 'pepe',
  },
  FLOKI: {
    symbol: 'FLOKI',
    name: 'Floki',
    decimals: 18,
    address: '0xcf0c122c6b73ff809c693db761e7baebe62b6a2e',
    chainId: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/16746/small/PNG_image.png?1643184642',
    tags: ['tokens'],
    coingeckoId: 'floki',
  },
  SHIB: {
    symbol: 'SHIB',
    name: 'SHIBA INU',
    decimals: 18,
    address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    chainId: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/11939/small/shiba.png?1622619446',
    tags: ['tokens'],
    coingeckoId: 'shiba-inu',
  },
  WBTC: {
    symbol: 'WBTC',
    name: 'Wrapped BTC',
    decimals: 8,
    address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    chainId: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
    tags: ['tokens'],
    coingeckoId: 'wrapped-bitcoin',
  },
}
