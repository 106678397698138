/* eslint-disable @typescript-eslint/no-restricted-imports */
/* eslint-disable import/no-unused-modules */
import { LoadingRows as BaseLoadingRows } from 'components/Loader/styled'
import styled from 'styled-components'

export const LoadingRows = styled(BaseLoadingRows)`
  margin-top: 90px;
  min-width: 75%;
  max-width: 960px;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.8em;
  grid-template-columns: repeat(3, 1fr);
  padding: 8px;

  & > div:nth-child(4n + 1) {
    grid-column: 1 / 3;
  }
  & > div:nth-child(4n) {
    grid-column: 3 / 4;
    margin-bottom: 2em;
  }
`

export function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}
