export const Switcher = ({ onSwitch }: { onSwitch: () => void }) => {
  // THE SWITCHER HAS FILTERED BACKGROUND AND CUSTOMIZED ON ITS PATH
  return (
    <div
      style={{
        width: '100%',
        height: '5px',
        background: '#efefef',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '99',
      }}
    >
      <svg
        style={{
          cursor: 'pointer',
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="55"
        height="55"
        viewBox="0 0 55 55"
        fill="none"
      >
        <g onClick={onSwitch} filter="url(#filter0_d_78_3850)">
          <rect x="45" y="10" width="31" height="31" rx="15.5" transform="rotate(90 45 10)" fill="#AFBDC8" />
          <rect
            x="47"
            y="8"
            width="35"
            height="35"
            rx="17.5"
            transform="rotate(90 47 8)"
            stroke="#D0DCE4"
            strokeWidth="4"
          />
        </g>
        <path
          d="M28.7615 31.0943C28.8448 27.3054 28.8656 23.4957 28.8032 19.7068C28.8032 19.0406 29.8441 19.0406 29.8441 19.7068C29.9065 23.4957 29.8857 27.3054 29.8024 31.0943C29.7816 31.7605 28.7407 31.7605 28.7615 31.0943Z"
          fill="white"
        />
        <path
          d="M32.3631 29.2413C31.8218 29.7409 31.2805 30.2613 30.7393 30.761C30.4686 31.0108 30.2188 31.2606 29.9482 31.5104C29.6567 31.7811 29.3653 32.0933 28.9281 31.9892C28.5742 31.906 28.366 31.5729 28.1578 31.3023C27.9496 31.0108 27.7415 30.7193 27.5125 30.4487C27.0753 29.845 26.6173 29.2413 26.1801 28.6375C26.0136 28.4085 26.1385 28.0546 26.3675 27.9297C26.6381 27.784 26.9087 27.8881 27.0753 28.1171C27.4292 28.6167 27.8039 29.0955 28.1578 29.5952C28.3452 29.845 28.5117 30.0948 28.6991 30.3238C28.7824 30.4487 28.8865 30.5736 28.9697 30.6985C28.9905 30.7402 29.2195 30.9692 29.1987 31.0108C29.1155 31.0108 29.0322 31.0108 28.9489 30.99C28.9697 30.9692 28.9905 30.9483 29.0322 30.9275C29.0738 30.8651 29.1363 30.8234 29.1779 30.7818C29.282 30.6777 29.4069 30.5736 29.511 30.4695C29.74 30.2613 29.969 30.0532 30.198 29.8242C30.656 29.387 31.1348 28.9498 31.5928 28.5126C32.1133 28.0546 32.8627 28.7833 32.3631 29.2413Z"
          fill="white"
        />
        <path
          d="M31.6969 22.538C31.2389 22.1008 30.7601 21.6428 30.3021 21.2057C30.0731 20.9975 29.8649 20.7893 29.6359 20.5603C29.511 20.4354 29.3861 20.3313 29.2612 20.2064C29.2196 20.1648 29.1571 20.1023 29.1155 20.0607C29.0738 20.019 29.0322 19.9982 29.0114 19.9566C28.9697 19.9149 28.9697 19.9149 29.0114 19.9358C29.0946 19.9358 29.1987 19.9358 29.282 19.9358C29.3861 19.9149 29.3445 19.8317 29.3236 19.9358C29.3028 20.019 29.2196 20.0815 29.1779 20.1439C29.0946 20.2897 28.9906 20.4354 28.9073 20.5811C28.7199 20.8726 28.5326 21.1432 28.3452 21.4347C27.9705 21.9968 27.6166 22.5797 27.2418 23.1418C26.8879 23.7038 25.9719 23.1834 26.3467 22.6213C26.763 21.9759 27.1794 21.3306 27.5957 20.706C27.8039 20.3729 28.0121 20.0399 28.2411 19.7276C28.4285 19.4361 28.595 19.0614 28.9697 18.9573C29.3861 18.8324 29.6776 19.1447 29.9482 19.3945C30.2188 19.6443 30.4895 19.9149 30.7601 20.1648C31.3222 20.706 31.8843 21.2473 32.4464 21.7886C32.9252 22.2674 32.1757 22.996 31.6969 22.538Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_78_3850"
            x="4"
            y="-9.53674e-07"
            width="51"
            height="51"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology radius="6" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_78_3850" />
            <feOffset />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.898507 0 0 0 0 0.93079 0 0 0 0 0.954167 0 0 0 0.5 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_78_3850" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_78_3850" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  )
}
