/* eslint-disable import/no-unused-modules */

import { SupportedChainId } from 'constants/chains'

//! NOTE: ADDRESSES SHOULD BE LOWERCASE
const ZKSYNC_GOERLI_INPUT_TOKENS = [
  '0x46caa59e33feb040442cc9722922260cbddb3f6f', // USDC
  '0x0890a779a8675464b8d0bedaf62620ce1ea62e4a', // USDT
  '0xc3ec043c150c945652a09d7e47f856ac9fb0f893', // WETH
]

const ZKSYNC_MAINNET_INPUT_TOKENS = [
  '0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4', // USDC
  '0x493257fd37edb34451f62edf8d2a0c418852ba4c', // USDT
  '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91', // WETH
  '0x0bf4cb727b3f8092534d793893b2cc3348963dbf', // DERP
  '0x7d54a311d56957fa3c9a3e397ca9dc6061113ab3', // ZKPEPE
]

const BASE_TESTNET_INPUT_TOKENS = [
  '0x2a337c269e74af16d5a608956b675ade882220f3', // USDC
  '0xea6658dccf674ed9902b4439798b06ce048791e5', // USDT
  '0x4200000000000000000000000000000000000006', // WETH
]

const BASE_MAINNET_INPUT_TOKENS = [
  '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca', // USDbC
  '0x4200000000000000000000000000000000000006', // WETH
  '0xebb78043e29f4af24e6266a7d142f5a08443969e', // DERP
]

const OPBBNB_TESTNET_INPUT_TOKENS = [
  '0x4200000000000000000000000000000000000006', // WBNB
]

const OPBBNB_MAINNET_INPUT_TOKENS = [
  '0x4200000000000000000000000000000000000006', // WBNB
  '0x9e5aac1ba1a2e6aed6b32689dfcf62a509ca96f3', // USDT
  '0xebb78043e29f4af24e6266a7d142f5a08443969e', // DERP
  '0xd21ee5e3b2829c07d1bfebfaddff9777235c0dc7', // derps
]

export const _SUPPORTED_INPUT_TOKEN_TO_ZAP: { [chainId: number]: string[] } = {
  [SupportedChainId.ZKSYNC_TESTNET]: ZKSYNC_GOERLI_INPUT_TOKENS,
  [SupportedChainId.ZKSYNC_MAINNET]: ZKSYNC_MAINNET_INPUT_TOKENS,
  [SupportedChainId.BASE_TESTNET]: BASE_TESTNET_INPUT_TOKENS,
  [SupportedChainId.BASE_MAINNET]: BASE_MAINNET_INPUT_TOKENS,
  [SupportedChainId.OPBNB_TESTNET]: OPBBNB_TESTNET_INPUT_TOKENS,
  [SupportedChainId.OPBNB_MAINNET]: OPBBNB_MAINNET_INPUT_TOKENS,
}

export const SUPPORTED_CHAIN_FOR_ZAP_TO_EARN = [
  SupportedChainId.ZKSYNC_MAINNET,
  SupportedChainId.BASE_MAINNET,
  SupportedChainId.OPBNB_MAINNET,
  SupportedChainId.ZKSYNC_TESTNET,
  SupportedChainId.BASE_TESTNET,
  SupportedChainId.OPBNB_TESTNET,
  SupportedChainId.OPBNB_MAINNET,
]
