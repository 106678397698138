/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { Brc20Abi, Brc20AbiInterface } from "../Brc20Abi";

const _abi = [
  {
    type: "constructor",
    inputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "Approval",
    type: "event",
    inputs: [
      {
        name: "owner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "spender",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "value",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
    signature:
      "0x8c5be1e5ebec7d5bd14f71427d1e84f3dd0314c0f7b2291e5b200ac8c7c3b925",
  },
  {
    name: "Transfer",
    type: "event",
    inputs: [
      {
        name: "from",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "value",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
    signature:
      "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  },
  {
    name: "DOMAIN_SEPARATOR",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bytes32",
        value:
          "0x1a0110d50afb8278f07c70bcdb5c90f87ae6e012d0c8d4915412eeb13719174b",
        internalType: "bytes32",
      },
    ],
    constant: true,
    signature: "0x3644e515",
    stateMutability: "view",
  },
  {
    name: "DOMAIN_TYPEHASH",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bytes32",
        value:
          "0x8b73c3c69bb8fe3d512ecc4cf759cc79239f7b179b0ffacaa9a75d522b39400f",
        internalType: "bytes32",
      },
    ],
    constant: true,
    signature: "0x20606b70",
    stateMutability: "view",
  },
  {
    name: "PERMIT_TYPEHASH",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bytes32",
        value:
          "0x6e71edae12b1b97f4d1f60370fef10105fa2faae0126114a169c64845d6126c9",
        internalType: "bytes32",
      },
    ],
    constant: true,
    signature: "0x30adf81f",
    stateMutability: "view",
  },
  {
    name: "allowance",
    type: "function",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    constant: true,
    signature: "0xdd62ed3e",
    stateMutability: "view",
  },
  {
    name: "approve",
    type: "function",
    inputs: [
      {
        name: "spender",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    signature: "0x095ea7b3",
    stateMutability: "nonpayable",
  },
  {
    name: "balanceOf",
    type: "function",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    constant: true,
    signature: "0x70a08231",
    stateMutability: "view",
  },
  {
    name: "burn",
    type: "function",
    inputs: [
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    signature: "0x42966c68",
    stateMutability: "nonpayable",
  },
  {
    name: "decimals",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint8",
        value: "18",
        internalType: "uint8",
      },
    ],
    constant: true,
    signature: "0x313ce567",
    stateMutability: "view",
  },
  {
    name: "factory",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        value: "0x30fe32b88D7a8c82F940cDfD0e5bB0fBfd18807F",
        internalType: "address",
      },
    ],
    constant: true,
    signature: "0xc45a0155",
    stateMutability: "view",
  },
  {
    name: "mint",
    type: "function",
    inputs: [
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    signature: "0x40c10f19",
    stateMutability: "nonpayable",
  },
  {
    name: "name",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "string",
        value: "derps",
        internalType: "string",
      },
    ],
    constant: true,
    signature: "0x06fdde03",
    stateMutability: "view",
  },
  {
    name: "nonces",
    type: "function",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    constant: true,
    signature: "0x7ecebe00",
    stateMutability: "view",
  },
  {
    name: "permit",
    type: "function",
    inputs: [
      {
        name: "owner",
        type: "address",
        internalType: "address",
      },
      {
        name: "spender",
        type: "address",
        internalType: "address",
      },
      {
        name: "value",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "deadline",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "v",
        type: "uint8",
        internalType: "uint8",
      },
      {
        name: "r",
        type: "bytes32",
        internalType: "bytes32",
      },
      {
        name: "s",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    outputs: [],
    signature: "0xd505accf",
    stateMutability: "nonpayable",
  },
  {
    name: "symbol",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "string",
        value: "derps",
        internalType: "string",
      },
    ],
    constant: true,
    signature: "0x95d89b41",
    stateMutability: "view",
  },
  {
    name: "totalSupply",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        value: "0",
        internalType: "uint256",
      },
    ],
    constant: true,
    signature: "0x18160ddd",
    stateMutability: "view",
  },
  {
    name: "transfer",
    type: "function",
    inputs: [
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    signature: "0xa9059cbb",
    stateMutability: "nonpayable",
  },
  {
    name: "transferFrom",
    type: "function",
    inputs: [
      {
        name: "from",
        type: "address",
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    signature: "0x23b872dd",
    stateMutability: "nonpayable",
  },
];

export class Brc20Abi__factory {
  static readonly abi = _abi;
  static createInterface(): Brc20AbiInterface {
    return new utils.Interface(_abi) as Brc20AbiInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): Brc20Abi {
    return new Contract(address, _abi, signerOrProvider) as Brc20Abi;
  }
}
