/* eslint-disable import/no-unused-modules */
import { MixedRouteSDK, Trade } from '@derpdex/router-sdk'
import { Route as V3Route } from '@derpdex/sdk'
import { Currency, CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core'
import { Route as V2Route } from '@uniswap/v2-sdk'
import { Protocols, TokenDetails1inch, TokenDetailsXYFinance } from 'pages/Swap/hooks/types'

export enum TradeState {
  LOADING,
  INVALID,
  NO_ROUTE_FOUND,
  VALID,
}

// from https://github.com/Uniswap/routing-api/blob/main/lib/handlers/schema.ts

type TokenInRoute = Pick<Token, 'address' | 'chainId' | 'symbol' | 'decimals'>

export type V3PoolInRoute = {
  type: 'v3-pool'
  tokenIn: TokenInRoute
  tokenOut: TokenInRoute
  sqrtRatioX96: string
  liquidity: string
  tickCurrent: string
  fee: string
  amountIn?: string
  amountOut?: string

  // not used in the interface
  address?: string
}

type V2Reserve = {
  token: TokenInRoute
  quotient: string
}

export type V2PoolInRoute = {
  type: 'v2-pool'
  tokenIn: TokenInRoute
  tokenOut: TokenInRoute
  reserve0: V2Reserve
  reserve1: V2Reserve
  amountIn?: string
  amountOut?: string

  // not used in the interface
  // avoid returning it from the client-side smart-order-router
  address?: string
}

export interface QuoteData {
  quoteId?: string
  blockNumber: string
  amount: string
  amountDecimals: string
  gasPriceWei: string
  gasUseEstimate: string
  gasUseEstimateQuote: string
  gasUseEstimateQuoteDecimals: string
  gasUseEstimateUSD: string
  methodParameters?: { calldata: string; value: string }
  quote: string
  quoteDecimals: string
  quoteGasAdjusted: string
  quoteGasAdjustedDecimals: string
  route: Array<(V3PoolInRoute | V2PoolInRoute)[]>
  routeString: string
}

export class ClassicTrade<
  TInput extends Currency,
  TOutput extends Currency,
  TTradeType extends TradeType
> extends Trade<TInput, TOutput, TTradeType> {
  gasUseEstimateUSD: string | null | undefined
  blockNumber: string | null | undefined

  constructor({
    gasUseEstimateUSD,
    blockNumber,
    ...routes
  }: {
    gasUseEstimateUSD?: string | null
    blockNumber?: string | null
    v2Routes: {
      routev2: V2Route<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
    v3Routes: {
      routev3: V3Route<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
    _1inchRoutes?: {
      _1inchRoute: V3Route<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
    _xyFinanceRoutes?: {
      _xyFinanceRoute: V3Route<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
    tradeType: TTradeType
    mixedRoutes?: {
      mixedRoute: MixedRouteSDK<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
  }) {
    super(routes)
    this.blockNumber = '808080'
    this.gasUseEstimateUSD = gasUseEstimateUSD
  }
}

export type InterfaceTrade = ClassicTrade<Currency, Currency, TradeType>

export enum QuoteState {
  SUCCESS = 'Success',
  NOT_FOUND = 'Not found',
}

export type QuoteResult =
  | {
      state: QuoteState.NOT_FOUND
      data?: undefined
    }
  | {
      state: QuoteState.SUCCESS
      data: QuoteData
    }

export type TradeResult =
  | {
      state: QuoteState.NOT_FOUND
      trade?: undefined
    }
  | {
      state: QuoteState.SUCCESS
      trade: InterfaceTrade
    }

export type XYFinanceTradeResult =
  | {
      state: QuoteState.NOT_FOUND
      trade?: undefined
      xySwapProvider?: undefined
    }
  | {
      state: QuoteState.SUCCESS
      trade: InterfaceTrade
      xySwapProvider: string
    }

export enum PoolType {
  V2Pool = 'v2-pool',
  V3Pool = 'v3-pool',
}

// swap router API special cases these strings to represent native currencies
// all chains have "ETH" as native currency symbol except for polygon
export enum SwapRouterNativeAssets {
  MATIC = 'MATIC',
  ETH = 'ETH',
  BNB = 'BNB',
}

export interface QuoteDataOn1inch {
  fromToken: TokenDetails1inch
  toToken: TokenDetails1inch
  fromTokenAmount: string
  toTokenAmount: string
  protocols: Array<Protocols[][] | Protocols[][][]>
  estimatedGas: number
}

interface XYFINACE_SRC_SWAP_DESCRIPTIONS {
  chainId: number
  dexNames: Array<string>
  dstTokenAddress: string
  dstTokenAmount: string
  provider: string
  srcTokenAddress: string
  srcTokenAmount: string
}

type WITHHOLDINGFEETOKEN = TokenDetailsXYFinance
export interface QuoteDataOnXYFinance {
  affiliateFeeAmount: string
  bridgeDescription: any | null
  contractAddress: string
  dstChainId: number
  dstQuoteToken: TokenDetailsXYFinance
  dstQuoteTokenAddress: string
  dstQuoteTokenAmount: string
  dstQuoteTokenUsdValue: string
  dstSwapDescription: any | null
  estimatedGas: string
  estimatedTransferTime: number
  minReceiveAmount: string
  routeType: string
  slippage: number
  srcChainId: number
  srcQuoteToken: TokenDetailsXYFinance
  srcQuoteTokenAddress: string
  srcQuoteTokenAmount: string
  srcQuoteTokenUsdValue: string
  srcSwapDescription: XYFINACE_SRC_SWAP_DESCRIPTIONS
  tags: Array<string>
  transactionCounts: number
  withholdingFeeAmount: string
  withholdingFeeToken: WITHHOLDINGFEETOKEN
}

export interface XYFinanceResponse {
  routes: QuoteDataOnXYFinance[]
  success: boolean
}
