/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  Brc20FactoryAbi,
  Brc20FactoryAbiInterface,
} from "../Brc20FactoryAbi";

const _abi = [
  {
    type: "constructor",
    inputs: [
      {
        name: "_signers",
        type: "address[]",
        internalType: "address[]",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    name: "BRC20Created",
    type: "event",
    inputs: [
      {
        name: "sender",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "dog20",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
    signature:
      "0xcafacc4a24deda55f94fc8bf5cb205b02aee745025f72472fe3e6e38e2a8b266",
  },
  {
    name: "Burned",
    type: "event",
    inputs: [
      {
        name: "token",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "from",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "fee",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "receiver",
        type: "string",
        indexed: false,
        internalType: "string",
      },
    ],
    anonymous: false,
    signature:
      "0x67a4f16c43f1ae602bc49450a4dee166bb3dc5d27807220f67113b799f1cdb50",
  },
  {
    name: "FeeChanged",
    type: "event",
    inputs: [
      {
        name: "oldFee",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "newFee",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
    ],
    anonymous: false,
    signature:
      "0x5fc463da23c1b063e66f9e352006a7fbe8db7223c455dc429e881a2dfe2f94f1",
  },
  {
    name: "Minted",
    type: "event",
    inputs: [
      {
        name: "token",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "txid",
        type: "string",
        indexed: false,
        internalType: "string",
      },
    ],
    anonymous: false,
    signature:
      "0xafe8c5a4c36942a61550534b714a0076ab46ef8ef23e361572aad8b7ca2ac27d",
  },
  {
    name: "OwnerChanged",
    type: "event",
    inputs: [
      {
        name: "oldOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "newOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
    signature:
      "0xb532073b38c83145e3e5135377a08bf9aab55bc0fd7c1179cd4fb995d2a5159c",
  },
  {
    name: "SignerAdded",
    type: "event",
    inputs: [
      {
        name: "sender",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "account",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
    signature:
      "0x12146497b3b826918ec47f0cac7272a09ed06b30c16c030e99ec48ff5dd60b47",
  },
  {
    name: "SignerRemoved",
    type: "event",
    inputs: [
      {
        name: "sender",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "account",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
    signature:
      "0x98d1ebbe00ae92a5de96a0f49742a8afa89f42363592bc2e7cfaaed68b45e7a6",
  },
  {
    name: "DOMAIN_SEPARATOR",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bytes32",
        value:
          "0xdfb8e6017a8b6bec1870cad1927b11c81b6e6ca2291fd04cf137eb9683aa74db",
        internalType: "bytes32",
      },
    ],
    constant: true,
    signature: "0x3644e515",
    stateMutability: "view",
  },
  {
    name: "DOMAIN_TYPEHASH",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bytes32",
        value:
          "0x8b73c3c69bb8fe3d512ecc4cf759cc79239f7b179b0ffacaa9a75d522b39400f",
        internalType: "bytes32",
      },
    ],
    constant: true,
    signature: "0x20606b70",
    stateMutability: "view",
  },
  {
    name: "MINT_TYPEHASH",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bytes32",
        value:
          "0xeea7c16dad6ea4efa98ec5cafb98c5c88b2a1c7bd8bc4315e854aded9e3261fc",
        internalType: "bytes32",
      },
    ],
    constant: true,
    signature: "0xf76fc35e",
    stateMutability: "view",
  },
  {
    name: "addSigner",
    type: "function",
    inputs: [
      {
        name: "account",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    signature: "0xeb12d61e",
    stateMutability: "nonpayable",
  },
  {
    name: "authorized",
    type: "function",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    constant: true,
    signature: "0xb9181611",
    stateMutability: "view",
  },
  {
    name: "buildMintSeparator",
    type: "function",
    inputs: [
      {
        name: "token",
        type: "address",
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "txid",
        type: "string",
        internalType: "string",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    constant: true,
    signature: "0x32aab8eb",
    stateMutability: "view",
  },
  {
    name: "burn",
    type: "function",
    inputs: [
      {
        name: "token",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "receiver",
        type: "string",
        internalType: "string",
      },
    ],
    outputs: [],
    payable: true,
    signature: "0x15f570dc",
    stateMutability: "payable",
  },
  {
    name: "createBRC20",
    type: "function",
    inputs: [
      {
        name: "name",
        type: "string",
        internalType: "string",
      },
      {
        name: "symbol",
        type: "string",
        internalType: "string",
      },
      {
        name: "decimals",
        type: "uint8",
        internalType: "uint8",
      },
    ],
    outputs: [
      {
        name: "brc20",
        type: "address",
        internalType: "address",
      },
    ],
    signature: "0x673199d1",
    stateMutability: "nonpayable",
  },
  {
    name: "fee",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        value: "10000000000000000",
        internalType: "uint256",
      },
    ],
    constant: true,
    signature: "0xddca3f43",
    stateMutability: "view",
  },
  {
    name: "indexes",
    type: "function",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    constant: true,
    signature: "0x2db78d93",
    stateMutability: "view",
  },
  {
    name: "mint",
    type: "function",
    inputs: [
      {
        name: "token",
        type: "address",
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "txid",
        type: "string",
        internalType: "string",
      },
      {
        name: "v",
        type: "uint8[]",
        internalType: "uint8[]",
      },
      {
        name: "r",
        type: "bytes32[]",
        internalType: "bytes32[]",
      },
      {
        name: "s",
        type: "bytes32[]",
        internalType: "bytes32[]",
      },
    ],
    outputs: [],
    signature: "0x6db4e99b",
    stateMutability: "nonpayable",
  },
  {
    name: "owner",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        value: "0xfD848A8bD6C1A583B52bA8630C48172FB3d57b66",
        internalType: "address",
      },
    ],
    constant: true,
    signature: "0x8da5cb5b",
    stateMutability: "view",
  },
  {
    name: "parameters",
    type: "function",
    inputs: [],
    outputs: [
      {
        name: "name",
        type: "string",
        value: {
          "0": "",
          "1": "",
          "2": "0",
          name: "",
          symbol: "",
          decimals: "0",
        },
        internalType: "string",
      },
      {
        name: "symbol",
        type: "string",
        internalType: "string",
      },
      {
        name: "decimals",
        type: "uint8",
        internalType: "uint8",
      },
    ],
    constant: true,
    signature: "0x89035730",
    stateMutability: "view",
  },
  {
    name: "removeSigner",
    type: "function",
    inputs: [
      {
        name: "account",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    signature: "0x0e316ab7",
    stateMutability: "nonpayable",
  },
  {
    name: "setFee",
    type: "function",
    inputs: [
      {
        name: "_fee",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    signature: "0x69fe0e2d",
    stateMutability: "nonpayable",
  },
  {
    name: "setOwner",
    type: "function",
    inputs: [
      {
        name: "_owner",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    signature: "0x13af4035",
    stateMutability: "nonpayable",
  },
  {
    name: "signers",
    type: "function",
    inputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    constant: true,
    signature: "0x2079fb9a",
    stateMutability: "view",
  },
  {
    name: "used",
    type: "function",
    inputs: [
      {
        name: "",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    constant: true,
    signature: "0xb07c411f",
    stateMutability: "view",
  },
  {
    name: "withdraw",
    type: "function",
    inputs: [
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    signature: "0x51cff8d9",
    stateMutability: "nonpayable",
  },
];

export class Brc20FactoryAbi__factory {
  static readonly abi = _abi;
  static createInterface(): Brc20FactoryAbiInterface {
    return new utils.Interface(_abi) as Brc20FactoryAbiInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): Brc20FactoryAbi {
    return new Contract(address, _abi, signerOrProvider) as Brc20FactoryAbi;
  }
}
