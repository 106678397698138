import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@derpdex/sdk'
import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'

import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

const DEFAULT_NETWORKS = [SupportedChainId.MAINNET, SupportedChainId.GOERLI]

function constructSameAddressMap(address: string, additionalNetworks: SupportedChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

export const UNISWAP_NFT_AIRDROP_CLAIM_ADDRESS = '0x8B799381ac40b838BBA4131ffB26197C432AFe78'

export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V2_FACTORY_ADDRESS)
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')

// celo v3 addresses
const CELO_V3_CORE_FACTORY_ADDRESSES = '0xAfE208a311B21f13EF87E33A90049fC17A7acDEc'
const CELO_V3_MIGRATOR_ADDRESSES = '0x3cFd4d48EDfDCC53D3f173F596f621064614C582'
const CELO_MULTICALL_ADDRESS = '0x633987602DE5C4F337e3DbF265303A1080324204'
const CELO_QUOTER_ADDRESSES = '0x82825d0554fA07f7FC52Ab63c961F330fdEFa8E8'
const CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x3d79EdAaBC0EaB6F08ED885C05Fc0B014290D95A'
const CELO_TICK_LENS_ADDRESSES = '0x5f115D9113F88e0a0Db1b5033D90D4a9690AcD3D'

// BNB v3 addresses
const BNB_V3_CORE_FACTORY_ADDRESSES = '0xdB1d10011AD0Ff90774D0C6Bb92e5C5c8b4461F7'
const BNB_V3_MIGRATOR_ADDRESSES = '0x32681814957e0C13117ddc0c2aba232b5c9e760f'
const BNB_MULTICALL_ADDRESS = '0x963Df249eD09c358A4819E39d9Cd5736c3087184'
const BNB_QUOTER_ADDRESSES = '0x78D78E420Da98ad378D7799bE8f4AF69033EB077'
const BNB_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x7b8A01B39D58278b5DE7e48c8449c9f4F5170613'
const BNB_TICK_LENS_ADDRESSES = '0xD9270014D396281579760619CCf4c3af0501A47C'

// optimism goerli addresses
const OPTIMISM_GOERLI_V3_CORE_FACTORY_ADDRESSES = '0xB656dA17129e7EB733A557f4EBc57B76CFbB5d10'
const OPTIMISM_GOERLI_V3_MIGRATOR_ADDRESSES = '0xf6c55fBe84B1C8c3283533c53F51bC32F5C7Aba8'
const OPTIMISM_GOERLI_MULTICALL_ADDRESS = '0x07F2D8a2a02251B62af965f22fC4744A5f96BCCd'
const OPTIMISM_GOERLI_QUOTER_ADDRESSES = '0x9569CbA925c8ca2248772A9A4976A516743A246F'
const OPTIMISM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x39Ca85Af2F383190cBf7d7c41ED9202D27426EF6'
const OPTIMISM_GOERLI_TICK_LENS_ADDRESSES = '0xe6140Bd164b63E8BfCfc40D5dF952f83e171758e'

// arbitrum goerli v3 addresses
const ARBITRUM_GOERLI_V3_CORE_FACTORY_ADDRESSES = '0x4893376342d5D7b3e31d4184c08b265e5aB2A3f6'
const ARBITRUM_GOERLI_V3_MIGRATOR_ADDRESSES = '0xA815919D2584Ac3F76ea9CB62E6Fd40a43BCe0C3'
const ARBITRUM_GOERLI_MULTICALL_ADDRESS = '0x8260CB40247290317a4c062F3542622367F206Ee'
const ARBITRUM_GOERLI_QUOTER_ADDRESSES = '0x1dd92b83591781D0C6d98d07391eea4b9a6008FA'
const ARBITRUM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x622e4726a167799826d1E1D150b076A7725f5D81'
const ARBITRUM_GOERLI_TICK_LENS_ADDRESSES = '0xb52429333da969a0C79a60930a4Bf0020E5D1DE8'

// ZkSync goerli v3 addresses
const ZKSYNC_GOERLI_V3_CORE_FACTORY_ADDRESSES = '0x9C260E394a96BB3E6836dAE8B9f2075D0b128e83'
const ZKSYNC_GOERLI_V3_MIGRATOR_ADDRESSES = '0xC55656d0f182E3E463F05Edb2BCBFc6e854BF5Cd'
const ZKSYNC_GOERLI_MULTICALL_ADDRESS = '0x1d1C59e64e5265E4A6933c6f016cf2C5604B04a1'
const ZKSYNC_GOERLI_QUOTER_ADDRESSES = '0x0c225eF0Df2655bdDC457c1674301C83b7B4Ed8a'
const ZKSYNC_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0xf70B4CD44b0750f2eEbb1A943f7a6c27C67E98C3'
const ZKSYNC_GOERLI_TICK_LENS_ADDRESSES = '0xB00156710AbB3D1186C7c2C78CEdDcAECA6aC45B'
const ZKSYNC_GOERLI_ZAP_TO_EARN_ADDRESSES = '0xF7230986C6519bE814cBE5A30765a7640C91A44F'

// TODO Zksync mainnet v3 addresses
const ZKSYNC_MAINNET_V3_CORE_FACTORY_ADDRESSES = '0x52A1865eb6903BC777A02Ae93159105015CA1517'
const ZKSYNC_MAINNET_V3_MIGRATOR_ADDRESSES = '0xb1ef06BCC2A8F63597d5779c00D72b2ae4bb592C'
const ZKSYNC_MAINNET_MULTICALL_ADDRESS = '0x8194228387f6605e05496e4ECED98851570E25b3'
const ZKSYNC_MAINNET_QUOTER_ADDRESSES = '0x48237655EFC513a79409882643eC987591dd6a81'
const ZKSYNC_MAINNET_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x7F4cB0666B700dF62E7fD0aB30e7C354Aa0A1890'
const ZKSYNC_MAINNET_TICK_LENS_ADDRESSES = '0xc5A4875617A4935f2f063B050b66925DDd1FB2C0'
const ZKSYNC_MAINNET_ZAP_TO_EARN_ADDRESSES = '0x3e662cFEbc51C88EC7b19888Ac341689bB33D178'

// TODO Basechain goerli v3 addresses
const BASE_GOERLI_V3_CORE_FACTORY_ADDRESSES = '0x27E361645A6E40679C4FA087b4f098ABd8073497'
const BASE_GOERLI_V3_MIGRATOR_ADDRESSES = '0x0000000000000000000000000000000000000000'
const BASE_GOERLI_MULTICALL_ADDRESS = '0xB206027a9E0E13F05eBEFa5D2402Bab3eA716439'
const BASE_GOERLI_QUOTER_ADDRESSES = '0x540706b32D1C951434033faB02DBb26778Db1d03'
const BASE_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x21518F657EEdD32717940FEF347c5eBFdc1eE1F5'
const BASE_GOERLI_TICK_LENS_ADDRESSES = '0x70d99Fb55c086ef905a04b277369512A1F925EBD'
const BASE_GOERLI_ZAP_TO_EARN_ADDRESSES = '0x0B1704eb4E08a3421575cBE1AcBC4dBa5e7B1eBC'

// TODO Basechain mainnet v3 addresses
const BASE_MAINNET_V3_CORE_FACTORY_ADDRESSES = '0xedDef4273518b137CDbcB3a7FA1C6a688303dFe2'
const BASE_MAINNET_V3_MIGRATOR_ADDRESSES = '0x0000000000000000000000000000000000000000'
const BASE_MAINNET_MULTICALL_ADDRESS = '0xe36ABD2f6512fE90b7c9Ed920565bCCE7E86eE0d'
const BASE_MAINNET_QUOTER_ADDRESSES = '0x777Dc769bb69F3fd12846a928193765847F234Af'
const BASE_MAINNET_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0xAb20a869E43b7Fe99b7aA785a70A0F454e28E6c2'
const BASE_MAINNET_TICK_LENS_ADDRESSES = '0x7eEF21C85CaD7aF0DAb712568bdB862349D3DDe3'
const BASE_MAINNET_ZAP_TO_EARN_ADDRESSES = '0x77988cE2559483f5109288b06494F9D06433e108'

// TODO OpBNB Testnet v3 addresses
const OPBNB_TESTNET_V3_CORE_FACTORY_ADDRESSES = '0x3149eB187fF0ee3A46003400c444DFbaF8b8a7FC'
const OPBNB_TESTNET_V3_MIGRATOR_ADDRESSES = '0x0000000000000000000000000000000000000000'
const OPBNB_TESTNET_MULTICALL_ADDRESS = '0xFBb52A5235931B53f370Db40Ef0a9d9e374D05bD'
const OPBNB_TESTNET_QUOTER_ADDRESSES = '0x6dD28Bf07F36030542f53B23255967c1418D3f51'
const OPBNB_TESTNET_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x626f3d7aa23888126AFCeA4537b7c54BcB3F2BE3'
const OPBNB_TESTNET_TICK_LENS_ADDRESSES = '0xD16a32F1A8d2c6C9203A85Bf28AF8F94d84dF346'
const OPBNB_TESTNET_ZAP_TO_EARN_ADDRESSES = '0x8D9E74fEe1b6ef7eC715e7183b2f11939C7684c9'

const OPBNB_MAINNET_V3_CORE_FACTORY_ADDRESSES = '0xb91331Ea9539ee881e3A45191076c454E482dAc7'
const OPBNB_MAINNET_V3_MIGRATOR_ADDRESSES = '0x0000000000000000000000000000000000000000'
const OPBNB_MAINNET_MULTICALL_ADDRESS = '0x4C6Aee4DcE7344FB7ec1666A8380a2405947eB42'
const OPBNB_MAINNET_QUOTER_ADDRESSES = '0xd068aA2be5F7605ABbFC766AA870bDAa430E6d4C'
const OPBNB_MAINNET_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x777Dc769bb69F3fd12846a928193765847F234Af'
const OPBNB_MAINNET_TICK_LENS_ADDRESSES = '0xDDC120d43f79F5F47316916834DF814ed4989F97'
const OPBNB_MAINNET_ZAP_TO_EARN_ADDRESSES = '0xb1ec660408Ae37e6EA2c82d196638C4Af66B1e99' // TBU

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS, [
    SupportedChainId.OPTIMISM,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.BNB]: BNB_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.ZKSYNC_TESTNET]: ZKSYNC_GOERLI_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.ZKSYNC_MAINNET]: ZKSYNC_MAINNET_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.BASE_TESTNET]: BASE_GOERLI_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.BASE_MAINNET]: BASE_MAINNET_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.OPBNB_TESTNET]: OPBNB_TESTNET_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.OPBNB_MAINNET]: OPBNB_MAINNET_V3_CORE_FACTORY_ADDRESSES,
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.BNB]: BNB_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.ZKSYNC_TESTNET]: ZKSYNC_GOERLI_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.ZKSYNC_MAINNET]: ZKSYNC_MAINNET_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.BASE_TESTNET]: BASE_GOERLI_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.BASE_MAINNET]: BASE_MAINNET_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.OPBNB_TESTNET]: OPBNB_TESTNET_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.OPBNB_MAINNET]: OPBNB_MAINNET_V3_MIGRATOR_ADDRESSES,
}

export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
  [SupportedChainId.CELO]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.BNB]: BNB_MULTICALL_ADDRESS,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_MULTICALL_ADDRESS,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_MULTICALL_ADDRESS,
  [SupportedChainId.ZKSYNC_TESTNET]: ZKSYNC_GOERLI_MULTICALL_ADDRESS,
  [SupportedChainId.ZKSYNC_MAINNET]: ZKSYNC_MAINNET_MULTICALL_ADDRESS,
  [SupportedChainId.BASE_TESTNET]: BASE_GOERLI_MULTICALL_ADDRESS,
  [SupportedChainId.BASE_MAINNET]: BASE_MAINNET_MULTICALL_ADDRESS,
  [SupportedChainId.OPBNB_TESTNET]: OPBNB_TESTNET_MULTICALL_ADDRESS,
  [SupportedChainId.OPBNB_MAINNET]: OPBNB_MAINNET_MULTICALL_ADDRESS,
}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}

export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_QUOTER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_QUOTER_ADDRESSES,
  [SupportedChainId.BNB]: BNB_QUOTER_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_QUOTER_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_QUOTER_ADDRESSES,
  [SupportedChainId.ZKSYNC_TESTNET]: ZKSYNC_GOERLI_QUOTER_ADDRESSES,
  [SupportedChainId.ZKSYNC_MAINNET]: ZKSYNC_MAINNET_QUOTER_ADDRESSES,
  [SupportedChainId.BASE_TESTNET]: BASE_GOERLI_QUOTER_ADDRESSES,
  [SupportedChainId.BASE_MAINNET]: BASE_MAINNET_QUOTER_ADDRESSES,
  [SupportedChainId.OPBNB_TESTNET]: OPBNB_TESTNET_QUOTER_ADDRESSES,
  [SupportedChainId.OPBNB_MAINNET]: OPBNB_MAINNET_QUOTER_ADDRESSES,
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xC36442b4a4522E871399CD717aBDD847Ab11FE88', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.BNB]: BNB_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.ZKSYNC_TESTNET]: ZKSYNC_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.ZKSYNC_MAINNET]: ZKSYNC_MAINNET_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.BASE_TESTNET]: BASE_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.BASE_MAINNET]: BASE_MAINNET_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.OPBNB_TESTNET]: OPBNB_TESTNET_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.OPBNB_MAINNET]: OPBNB_MAINNET_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_TICK_LENS_ADDRESSES,
  [SupportedChainId.CELO]: CELO_TICK_LENS_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_TICK_LENS_ADDRESSES,
  [SupportedChainId.BNB]: BNB_TICK_LENS_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_TICK_LENS_ADDRESSES,
  [SupportedChainId.ZKSYNC_TESTNET]: ZKSYNC_GOERLI_TICK_LENS_ADDRESSES,
  [SupportedChainId.ZKSYNC_MAINNET]: ZKSYNC_MAINNET_TICK_LENS_ADDRESSES,
  [SupportedChainId.BASE_TESTNET]: BASE_GOERLI_TICK_LENS_ADDRESSES,
  [SupportedChainId.BASE_MAINNET]: BASE_MAINNET_TICK_LENS_ADDRESSES,
  [SupportedChainId.OPBNB_TESTNET]: OPBNB_TESTNET_TICK_LENS_ADDRESSES,
  [SupportedChainId.OPBNB_MAINNET]: OPBNB_MAINNET_TICK_LENS_ADDRESSES,
}

export const ZAP_TO_EARN_ADDRESSES: AddressMap = {
  [SupportedChainId.ZKSYNC_TESTNET]: ZKSYNC_GOERLI_ZAP_TO_EARN_ADDRESSES,
  [SupportedChainId.ZKSYNC_MAINNET]: ZKSYNC_MAINNET_ZAP_TO_EARN_ADDRESSES,
  [SupportedChainId.BASE_TESTNET]: BASE_GOERLI_ZAP_TO_EARN_ADDRESSES,
  [SupportedChainId.BASE_MAINNET]: BASE_MAINNET_ZAP_TO_EARN_ADDRESSES,
  [SupportedChainId.OPBNB_TESTNET]: OPBNB_TESTNET_ZAP_TO_EARN_ADDRESSES,
  [SupportedChainId.OPBNB_MAINNET]: OPBNB_MAINNET_ZAP_TO_EARN_ADDRESSES,
}
