/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  UnsupportedProtocol,
  UnsupportedProtocolInterface,
} from "../UnsupportedProtocol";

const _abi = [
  {
    inputs: [],
    name: "UnsupportedProtocolError",
    type: "error",
  },
  {
    stateMutability: "nonpayable",
    type: "fallback",
  },
];

const _bytecode =
  "0x608080604052346013576043908160198239f35b600080fdfe60808060405234603157807fea3559ef0000000000000000000000000000000000000000000000000000000060049252fd5b600080fdfea164736f6c6343000811000a";

export class UnsupportedProtocol__factory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer);
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<UnsupportedProtocol> {
    return super.deploy(overrides || {}) as Promise<UnsupportedProtocol>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): UnsupportedProtocol {
    return super.attach(address) as UnsupportedProtocol;
  }
  connect(signer: Signer): UnsupportedProtocol__factory {
    return super.connect(signer) as UnsupportedProtocol__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): UnsupportedProtocolInterface {
    return new utils.Interface(_abi) as UnsupportedProtocolInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): UnsupportedProtocol {
    return new Contract(address, _abi, signerOrProvider) as UnsupportedProtocol;
  }
}
